import { catchServiceErrors } from "@/utils/services-global";
import { AxiosGet, AxiosPatch, AxiosPost, GetData } from "./axios-service";

import ROUTES from "@/api/routes";
const { TIMEZONE } = ROUTES;

class TimezoneService {
  async getTimezonesKeys() {
    try {
      const response = await AxiosGet(TIMEZONE.TIMEZONE_ROUTE);
      return Promise.resolve(GetData(response) as Array<any>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async update(props: { line_item_id: number; timezone_ids: number[] }) {
    try {
      if (!props.timezone_ids.length) {
        return Promise.reject({
          success: false,
          message: "Timezone empty",
          errors: [],
        });
      }
      const response = await AxiosPost(TIMEZONE.TIMEZONE_ASSOCIATION_ROUTE, props);
      return Promise.resolve(GetData(response) as Array<any>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
}

export default new TimezoneService();

