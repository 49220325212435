import { EnumStatus, ResultDataEntity } from "@/interfaces/report";
/**
 * Tiempo de espera para reintentar
 */
export const delayAttempt: number = 8000;

export const DATA_RANGE_CUSTOM = "custom";
export const DATA_RANGE_LAST_7_DAYS = "last_7_days";
export const DATA_RANGE_LAST_30_DAYS = "last_30_days";

export async function isWillBeRetried(response: {
	success: boolean;
	status: EnumStatus;
	message: any;
	data: ResultDataEntity | string;
}) {
	if (response.success === false || typeof response.data === "string")
		return false;

	return [EnumStatus.PENDING].includes(response.status);
}

export async function isError(response: ResultDataEntity) {
	if (!response.response || typeof response.response === "string")
		return false;
	return [EnumStatus.ERROR].includes(response.response.status);
}

export function HasStatusAttempt(response: any, status: EnumStatus) {
	return response.response.status === status;
}

export function HasMessageAttempt(response: any, status: EnumStatus) {
	console.log("HasMessageAttempt", { response, status });
	return response.message === status;
}

export const matchedEntityName = {
	Scheduler_Report_List: "report.scheduler.table.title",
	Advertiser: "menu.advertiser",
	Campaign: "menu.campaign",
	"Line Item": "menu.lineItem",
	User: "menu.tools.user",
	List: "menu.tools.list",
	Modifier: "menu.tools.modifier",
	Segment: "menu.tools.segment",
	"Request Campaign": "menu.requestCampaign",
	Creative: "menu.creative",
	privatepois: "menu.personsMenu.privatePOIsTitle",
};

export function getMatchedName(key: string) {
	return matchedEntityName[key];
}
