import { AxiosGet } from "@/services/axios-service";
import { catchServiceErrors } from "@/utils/services-global";
import ROUTES from "@/api/routes";

const ROUTE = ROUTES.REPORT;

class LookerService {
  async performanceCampaignLookerUrl() {
    try {
      const response = await AxiosGet(ROUTE.REPORT_PERFORMANCE_CAMPAIGN_LOOKER_URL_ROUTE);
      return Promise.resolve(response.data?.response);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
}

export default new LookerService();
