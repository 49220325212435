import targetingService from "@/services/targeting-service";
import { CatcherError } from "../Util";
import TimezoneService from "@/services/timezone-service";

export const TimezoneModule = {
  namespaced: true,
  state: () => ({
    targeting_expressions: null,
    targeting_summary: {},
  }),
  mutations: {
    SET_TARGETING_EXPRESSIONS(state, _targeting_expressions: any) {
      state.targeting_expressions = _targeting_expressions;
    },
    SET_SUMMARY_TARGETING(state, targeting_summary: any) {
      state.targeting_summary = targeting_summary;
    },
  },
  getters: {
    getTargetingSummary(state) {
      return state.targeting_summary;
    },
  },
  actions: {
    async getTimezones({ commit }) {
      try {
        const response = await TimezoneService.getTimezonesKeys();
        return await Promise.resolve(response);
      } catch (error) {
        CatcherError(this.dispatch, error);
        return await Promise.reject(error);
      }
    },

    async update({ commit }, props: { line_item_id: number; timezone_ids: number[] }) {
      try {
        const response = await TimezoneService.update(props);
        return await Promise.resolve(response);
      } catch (error) {
        CatcherError(this.dispatch, error);
        return await Promise.reject(error);
      }
    },
  },
};

