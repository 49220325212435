import { ResultPaginate } from "@/interfaces/paginated";
import { User, UserDataCreate, UserDataUpdate } from "@/interfaces/user";
import UserService from "@/services/user-service";
import { catchError } from "@/store/Util";
import { resolveList } from "@/utils/resolveObjectArray";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";

export const UserModule = {
	namespaced: true,
	state: () => ({
		result_paginate: {} as ResultPaginate,
		users: [],
		user: {} as User,
		roles: [],
		positions:[],
		traffickers: [],
	}),
	mutations: {
		SET_RESULT_PAGINATED(state, _result_paginate: ResultPaginate) {
			state.result_paginate = _result_paginate;
		},
		SET_USERS(state, _users = []) {
			state.users = _users;
		},
		SET_USER(state, _user: User = {} as User) {
			state.user = _user;
		},
		SET_ROLES(state, _roles = []) {
			state.roles = _roles;
		},
		SET_POSITIONS(state, _positions = []) {
			state.positions = _positions;
		},
		SET_TRAFFICKERS(state, _traffickers = []) {
			state.traffickers = _traffickers;
		},
	},
	getters: {},
	actions: {
		async paginated({ commit }, params) {
			try {
				const response = await UserService.paginated(params);
				commit("SET_RESULT_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async download({}, params) {
			try {
				await UserService.download(params);
				return await Promise.resolve({});
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async list({ commit }, params) {
			try {
				const response = await UserService.list(params);
				commit("SET_USERS", resolveList(response));
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async traffickers({ commit }, params) {
			try {
				const response = await UserService.getTraffickers(params);
				commit("SET_TRAFFICKERS", resolveList(response));
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async listRoles({ commit }) {
			try {
				const response = await UserService.listRoles();
				commit("SET_ROLES", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async listPositions({ commit }) {
			try {
				const response = await UserService.listPositions();
				commit("SET_POSITIONS", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async create({}, user: UserDataCreate) {
			try {
				const response = await UserService.create(user);
				notificationService.notifySuccess(this, {
					to: "UsersIndex",
				} as Notification);
				return Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return Promise.reject(error);
			}
		},

		async update({ commit }, params: { user: UserDataUpdate; id: number }) {
			try {
				const { data, message } = await UserService.update(params.user, params.id);
				commit("SET_USER", data);				
				const notification = { to: "UsersIndex" } as Notification;
				if(message) Object.assign(notification, { message });				
				notificationService.notifySuccess(this, notification);
				return await Promise.resolve(data);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async show({ commit }, id: number) {
			try {
				const response = await UserService.show(id);
				commit("SET_USER", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async setAccount({}, account_id: number) {
			try {
				const response = await UserService.setAccount(account_id);
				/*
						notificationService.notifySuccess(this, {
							to: ""
						} as Notification);
						*/
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};
