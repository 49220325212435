import LookerService from "@/services/looker-service";
import { catchError } from "@/store/Util";

export const LookerModule = {
	namespaced: true,
	state: () => ({}),
	mutations: {},
	getters: {},
	actions: {
		async getPerformanceCampaignLookerUrl({ commit }) {
			try {
				const response : string =
					await LookerService.performanceCampaignLookerUrl();
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		
	},
};
