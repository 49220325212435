import { CustomList, CustomListDataCreate, List, Type } from "@/interfaces/custom_list";
import { ResultPaginate } from "@/interfaces/paginated";
import CustomListService from "@/services/custom-list-service";
import { catchError } from "@/store/Util";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
import TypeService from "@/services/type-service";
import lineItemTypeService from "@/services/line-item-type-service";
import { resolveList, resolveListParams } from "@/utils/resolveObjectArray";

export const CustomListModule = {
  namespaced: true,
  state: () => ({
    result_paginate: {} as ResultPaginate,
    custom_list: {} as CustomList,
    types: [] as List[],
    model_view: "" as String,
    budget_types: [
      {
        id: 1,
        value: "New",
      },
      {
        id: 2,
        value: "Existing",
      },
    ],
    campaigns_pacing: [],
    optimization_strategies: [],
    kpi_campaigns: [],
    strategies: [],
    unit_times: [],
    creative_weighting_methods: [],
    line_item_types: [],
    bid_strategy_list: [],
    lines_pacing_list: [],
    pacing_behaviour_list: [],
    catchup_behaviour_list: [],
    bidding_shadings_list: [],
    verb_usage_list: [],
    segment_tag_type_list: [],
    types_frecuency_caps: [],
  }),
  mutations: {
    SET_RESULT_PAGINATED(state, _result_paginate: ResultPaginate = {} as ResultPaginate) {
      state.result_paginate = _result_paginate;
    },
    SET_TYPES(state, _types: List[]) {
      state.types = _types;
    },
    SET_MODEL_VIEW(state, _modelView: String = "") {
      state.model_view = _modelView;
    },
    SET_CUSTOM_LIST(state, _custom_list: CustomList = {} as CustomList) {
      state.custom_list = _custom_list;
    },
    SET_BIDDING_SHADING_LIST(state, _bidding_shadings_list: CustomList = {} as CustomList) {
      state.bidding_shadings_list = _bidding_shadings_list;
    },
    SET_BUDGET_TYPES(state, _budget_types: [] = []) {
      state.budget_types = _budget_types;
    },
    SET_CAMPAIGNS_PACING(state, _campaigns_pacing: [] = []) {
      state.campaigns_pacing = _campaigns_pacing;
    },
    SET_OPTIMIZATION_STRATEGIES(state, _optimization_strategies: [] = []) {
      state.optimization_strategies = _optimization_strategies;
    },
    SET_KPI_CAMPAIGNS(state, _kpi_campaigns: [] = []) {
      state.kpi_campaigns = _kpi_campaigns;
    },
    SET_STRATEGIES(state, _strategies: [] = []) {
      state.strategies = _strategies;
    },
    SET_UNIT_TIMES(state, _unit_times: [] = []) {
      state.unit_times = _unit_times;
    },
    SET_CREATIVE_WEIGHTING_METHODS(state, _creative_weighting_methods: [] = []) {
      state.creative_weighting_methods = _creative_weighting_methods;
    },
    SET_LINE_ITEM_TYPES(state, _line_item_types: List[]) {
      state.line_item_types = _line_item_types;
    },
    SET_BID_STRATEGY_LIST(state, _bid_strategy_list: List[]) {
      state.bid_strategy_list = _bid_strategy_list;
    },
    SET_LINE_PACING_LIST(state, _lines_pacing_list: List[]) {
      state.lines_pacing_list = _lines_pacing_list;
    },
    SET_PACING_BEHAVIOUR_LIST(state, _pacing_behaviour_list: List[]) {
      state.pacing_behaviour_list = _pacing_behaviour_list;
    },
    SET_CATCHUP_BEHAVIOUR_LIST(state, _catchup_behaviour_list: List[]) {
      state.catchup_behaviour_list = _catchup_behaviour_list;
    },
    SET_VERB_USAGE_LIST(state, _verb_usage_list: List[]) {
      state.verb_usage_list = _verb_usage_list;
    },
    SET_SEGMENT_TAG_TYPE_LIST(state, _segment_tag_type_list) {
      state.segment_tag_type_list = _segment_tag_type_list;
    },
    SET_TYPES_FRECUENCY_CAPS_LIST(state, _types_frecuency_caps_list) {
      state.types_frecuency_caps = _types_frecuency_caps_list;
    },
  },
  getters: {},
  actions: {
    async paginated({ commit }, params) {
      try {
        const response = await CustomListService.paginated(params);
        commit("SET_RESULT_PAGINATED", response);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async download({}, params) {
      try {
        await CustomListService.download(params);
        return await Promise.resolve({});
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async create({ commit }, customList: CustomListDataCreate) {
      try {
        const response = await CustomListService.create(customList);
        commit("SET_CUSTOM_LIST", response);
        notificationService.notifySuccess(this, {
          to: {
            name: "CustomListEdit",
            params: { id: response.id },
          },
        } as Notification);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async show({ commit }, id: Number) {
      try {
        const response = await CustomListService.show(id);
        commit("SET_CUSTOM_LIST", response);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error, { to: "CustomListIndex" });
        return await Promise.reject(error);
      }
    },
    async update({ commit }, updates) {
      try {
        const response = await CustomListService.update(updates);
        commit("SET_CUSTOM_LIST", response);
        notificationService.notifySuccess(this, {} as Notification);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async uploadItems({}, params) {
      try {
        const response = await CustomListService.uploadItems(params.payload, params.function);
        notificationService.notifySuccess(this, { message: response.message } as Notification);
        return await Promise.resolve(response.data);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getTypes({ commit }) {
      try {
        const response: List[] = await TypeService.list();
        commit("SET_TYPES", response);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getLineItemTypes({ commit }) {
      try {
        const response: List[] = await lineItemTypeService.list();
        commit("SET_LINE_ITEM_TYPES", resolveList(response, true));
        return await Promise.resolve(resolveList(response, true));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getBidStrategies({ commit }) {
      try {
        const response: List[] = await CustomListService.bidStrategyList();
        commit("SET_BID_STRATEGY_LIST", resolveList(response));
        return await Promise.resolve(resolveList(response));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getLinesPacing({ commit }) {
      try {
        const response: List[] = await CustomListService.linePacingList();

        commit("SET_LINE_PACING_LIST", resolveList(response));
        return await Promise.resolve(resolveList(response));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getPacingBehaviour({ commit }) {
      try {
        const response = await CustomListService.PacingBehaviorList();
        const defaultState = { 1: "even", 2: "ahead" };
        const PacingBehaviorList =
          response && response.length > 0
            ? response.reduce((acc, item) => {
                acc[item.id] = item.description;
                return acc;
              }, {})
            : defaultState;
        commit("SET_PACING_BEHAVIOUR_LIST", resolveList(PacingBehaviorList));
        return await Promise.resolve(resolveList(PacingBehaviorList));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getCatchupBehaviour({ commit }) {
      try {
        const response = await CustomListService.CatchupBehaviorList();
        const CatchupBehaviorList = response.reduce((acc, item) => {
          acc[item.id] = item.description;
          return acc;
        }, {});
        commit("SET_CATCHUP_BEHAVIOUR_LIST", resolveList(CatchupBehaviorList));
        return await Promise.resolve(resolveList(CatchupBehaviorList));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getBiddingShadings({ commit }) {
      try {
        const response: List[] = await CustomListService.biddingShadingList();
        commit("SET_BIDDING_SHADING_LIST", resolveList(response));
        return await Promise.resolve(resolveList(response));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getViewByTypeSelected({ commit }, type: Type) {
      try {
        const response = await CustomListService.getViewByTypeSelected(type);
        commit("SET_MODEL_VIEW", response);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getBudgetTypes({ commit }) {
      try {
        const response = await CustomListService.budgetTypes();
        commit("SET_BUDGET_TYPES", resolveList(response));
        return await Promise.resolve(resolveList(response));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getCampaignPacing({ commit }) {
      try {
        const response = await CustomListService.campaignPacing();
        commit("SET_CAMPAIGNS_PACING", resolveList(response));
        return await Promise.resolve(resolveList(response));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getOptimizationStrategies({ commit }) {
      try {
        const response = await CustomListService.optimizationStrategies();
        commit("SET_OPTIMIZATION_STRATEGIES", resolveList(response));
        return await Promise.resolve(resolveList(response));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getKpiCampaigns({ commit }) {
      try {
        const response = await CustomListService.kpiCampaigns();
        commit("SET_KPI_CAMPAIGNS", resolveList(response));
        return await Promise.resolve(resolveList(response));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getStrategies({ commit }) {
      try {
        const response = await CustomListService.strategies();
        commit("SET_STRATEGIES", resolveList(response));
        return await Promise.resolve(resolveList(response));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getUnitTimes({ commit }) {
      try {
        const response = await CustomListService.unitTimes();
        commit("SET_UNIT_TIMES", resolveList(response));
        return await Promise.resolve(resolveList(response));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getCreativeWeightingMethods({ commit }) {
      try {
        const response = await CustomListService.CreativeWeightingMethods();
        commit("SET_CREATIVE_WEIGHTING_METHODS", resolveList(response));
        return await Promise.resolve(resolveList(response));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getVerbUsage({ commit }) {
      try {
        const response = await CustomListService.verbUsage();
        commit("SET_VERB_USAGE_LIST", resolveList(response));
        return await Promise.resolve(resolveList(response));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getSegmentTagType({ commit }) {
      try {
        const response = await CustomListService.segmentTagType();
        commit("SET_SEGMENT_TAG_TYPE_LIST", response);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getTypesFrecuencyCaps({ commit }) {
      try {
        const response = await CustomListService.typesFrecuecyCaps();
        commit("SET_TYPES_FRECUENCY_CAPS_LIST", resolveListParams(response, "extra", "description", false, true));
        return await Promise.resolve(resolveListParams(response, "extra", "description", false, true));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
  },
};

